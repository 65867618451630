// third-party
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//icons
import
{
   faTag,
   faCircleEuro,
   faMoneyBillTrendUp,
   faChartMixed,
   faUserGraduate,
   faMemo,
   faFolderOpen
} from '@fortawesome/pro-regular-svg-icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const report = {
   id: 'report',
   title: 'Report',
   type: 'group',
   permissions: ['superadmin','admin','erp_admin'],
   children: [
      {
         id: 'report-risorse-assegnate',
         title: 'Risorse assegnate',
         pageTitle: 'Risorse assegnate',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/risorse-assegnate',
         icon: () => <FontAwesomeIcon icon={faTag} size="lg" />,
      },
      {
         id: 'report-compensi',
         title: 'Compensi',
         pageTitle: 'Compensi',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/compensi',
         icon: () => <FontAwesomeIcon icon={faCircleEuro} size="lg" />,
      },
      {
         id: 'ricavi-attivita',
         title: 'Ricavi Attività',
         pageTitle: 'Ricavi Attività',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/ricavi-attivita',
         icon: () => <FontAwesomeIcon icon={faMoneyBillTrendUp} size="lg" />,
      },
      {
         id: 'analisi-ricavi',
         title: 'Marginalità',
         pageTitle: 'Marginalità',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/analisi-ricavi',
         icon: () => <FontAwesomeIcon icon={faChartMixed} size="lg" />,
      },
      {
         id: 'scadenze-corsi',
         title: 'Scadenze Corsi',
         pageTitle: 'Scadenze Corsi',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/scadenze-corsi',
         icon: () => <FontAwesomeIcon icon={faUserGraduate} size="lg" />,
      },
      {
         id: 'scadenze-documenti',
         title: 'Scadenze Documenti',
         pageTitle: 'Scadenze Documenti',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/scadenze-documenti',
         icon: () => <FontAwesomeIcon icon={faMemo} size="lg" />,
      },
      {
         id: 'pratiche',
         title: 'Pratiche',
         pageTitle: 'Pratiche',
         type: 'item',
         permissions: ['superadmin','admin','erp_admin'],
         url: '/report/pratiche',
         icon: () => <FontAwesomeIcon icon={faFolderOpen} size="lg" />,
      }
   ]
};

export default report;
