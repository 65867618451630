import { Navigate } from "react-router";
import useAuth from "hooks/useAuth";
import {
   Alert
} from "@mui/material";

const RouteProtection = ({ capabilities, children, redirect = null }) =>
{
   const {
      currentUserCan
   } = useAuth();

   if ( !currentUserCan(capabilities) )
   {
      if( redirect !== null )
      {
         return <Navigate to={redirect} replace />
      }

      return (
         <Alert variant="filled" severity="error">
         Non disponi di permessi sufficienti per questa sezione
         </Alert>
      )
   }

   return children;
}

export default RouteProtection;