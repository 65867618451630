import { Navigate } from "react-router";
import useAuth from "hooks/useAuth";

const PrivateElement = ({ capabilities, children, redirect = null }) =>
{
   const {
      currentUserCan
   } = useAuth();

   if ( !currentUserCan(capabilities) )
   {
      if( redirect !== null )
      {
         return <Navigate to={redirect} replace />
      }

      return null;
   }

   return children;
}

export default PrivateElement;